.Toastify__toast-theme--dark {
  background: #3d3d3d !important;
  color: #ffffff !important;
}

.Toastify__toast-theme--light {
  background: #ffffff !important;
  color: #3d3d3d !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #6cc0c0 !important;
  color: #fafafa !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #84c9ab !important;
  color: #296513 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #fde2a9 !important;
  color: #a87524 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #f4b0ba !important;
  color: #9a4141 !important;
}

.Toastify__progress-bar-theme--dark {
  background: #98c5e1 !important;
}

.Toastify__progress-bar--info {
  background: #4bafaf !important;
}

.Toastify__progress-bar--success {
  background: #60b992 !important;
}

.Toastify__progress-bar--warning {
  background: #fcd278 !important;
}

.Toastify__progress-bar--error {
  background: #ee8292 !important;
}

#nprogress .bar {
  height: 3px !important;
}

.toastui-calendar-template-time {
  white-space: pre-wrap;
}

/* overRide-above */

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Noto Sans TC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number'] {
  text-align: right;
}

::-webkit-scrollbar-track {
  /* 軌道 */
  /* -webkit-box-shadow: inset 0 2px 2px 1px #00000020;
  border-radius: 10px; */
  background-color: inherit;
  opacity: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: inherit;
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  /* background-color: #0496d2; */
  background-color: #bbbbbb;
  border-radius: 0.5rem;
}

@page {
  size: A4;
  margin: 10px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  * {
    break-inside: auto;
  }

  div * {
    box-shadow: none !important;
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(1deg);
  }

  70% {
    transform: rotate(-1deg);
  }

  100% {
    transform: rotate(0deg);
  }
}